export default class ProfileData {
  gender = "";

  salutation = "";

  title = "";

  birthdate = "";

  donorId = "";

  lastname = "";

  firstname = "";

  middlename = "";

  street = "";

  houseno = "";

  apartment = "";

  co = "";

  zipcode = "";

  city = "";

  county = "";

  state = "";

  houseName = "";

  email = "";

  mobile = "";

  landline = "";

  alternatePhone = "";

  password = "";
}
